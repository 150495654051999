import React from "react"
import TextBlock from "../components/global/article/textBlock"
import Banner from "../components/global/article/banner"
import BannerImageWithText from "../components/global/article/bannerWithText"
import Layout from "../components/global/layout"
import headerBannerImage from "../images/wind-burn-hero.jpg"
import brickBanner from "../images/brick-banner.jpg"
import moisturiserProduct from "../images/product-and-package/inm.jpg"
import medPlusProduct from "../images/product-and-package/mp.jpg"
import ultraProtect from "../images/product-and-package/ul.jpg"
import crackedLipsImage from "../images/cracked-lips-image.png"
import paginationBanner from "../images/sun-protection-up-next.jpg"
import Header from "../components/global/header"
import SEO from "../components/global/seo"

const tempData = {
  hero: {
    image: headerBannerImage,
    text: (
      <>
        <h1>
          <span className="large-letter">W</span>indburn.
        </h1>
      </>
    ),
    align: "left",
  },
  firstComponent: {
    product: ultraProtect,
    productLink: "/ultra-lip-balm/",
    text: (
      <>
        <h2>take precaution against windburn.</h2>
        <p>
          exposure to strong and cold wind for extended periods of time can
          cause skin redness called windburn. wind in low humidity can cause
          skin irritation which looks like a sunburn, even on cloudy days. plus,
          the wind has a drying effect which may heighten the symptoms.
        </p>
        <p>
          cooling of wind means people are less likely to seek shade or get back
          inside. if you are skiing, take extra precaution because higher
          altitudes increase your risk of UV exposure.
        </p>
        <p>
          there’s so many outdoor sports in the winter, so enjoy the great
          outdoors and prepare for your health and comfort. here’s a few tips to
          reduce the risk of windburn:
        </p>
        <p>
          wear protective sunscreen of at least 15 SPF, lotion for your face and
          lip balm for your lips
        </p>
        <p>
          cover exposed areas of skin including a scarf to wrap around your
          face. consider a turtleneck shirt to protect your neck
        </p>
        <p>
          even if you wear a ski mask, remember that your delicate lips are
          still exposed to the elements. take extra precaution by applying and
          reapplying lip balm. Blistex has multiple lip balm products with SPF
          15 or above.
        </p>
      </>
    ),
  },
  secondComponent: {
    image: brickBanner,
    text: (
      <>
        <h2>
          remedies for
          <span className="large">
            cracked <br /> & chapped
          </span>
        </h2>
        <h3>effective solutions to prevent dry lips</h3>
      </>
    ),
    align: "left",
  },
  thirdComponent: {
    product: moisturiserProduct,
    productLink: "/intensive-moisturiser/",
    text: (
      <>
        <h2>how will you know you are experiencing windburn?</h2>
        <p>
          if you get windburn, your skin may feel tender and have a burning
          sensation. if the windburn is extreme, you may develop blisters and
          you should consult your doctor.
        </p>

        <p>
          if you have minor windburn, here’s a few actions you can take to help
          heal:
        </p>

        <ul>
          <li>replenish the moisture by applying lip balm</li>
          <li>drink lots of water</li>
          <li>
            avoid hot water until you recover, including hot showers and hot
            beverages
          </li>
        </ul>

        <p>
          if skin begins to peel, you should not pick at the skin or scrub lips.
          continue to moisturize.
        </p>

        <p>
          it’s fun to play in the snow. enjoy the outdoors with friends and
          family. bring your Blistex lip balm along for the adventure and post
          your photos on the Blistex social media site.
        </p>
      </>
    ),
  },
  forthComponent: {
    text:
      "the skin on our lips is much thinner than other parts of the body so it’s imperitive that they’re looked after",
    image: crackedLipsImage,
    roundal: "the lip experts",
    backgroundColor: "black",
  },
  //   fifthComponent: {
  //     product: ultraProtect,
  //     productLink: "/ultra-lip-balm/",
  //     text: (
  //       <>
  //         <h2>ski, sun, lips</h2>

  //         <p>
  //           if you’re off on a skiing trip over winter, put Blistex at the top of
  //           your packing list. The combination of wind, sun and cold on lips can
  //           be intense, so be prepared.
  //         </p>
  //         <p>
  //           make sure you’ve got sunscreen with a high SPF for your face, Blistex
  //           to protect your lips and a scarf to protect any exposed skin on your
  //           neck.
  //         </p>
  //         <p>
  //           if you start to get a burning feeling on your skin, it could be
  //           windburn. steer clear of hot showers and drinks and apply moisture to
  //           your face and lips.
  //         </p>
  //         <p>
  //           with over 70 years of experience, we know that when the cold hits it’s
  //           time to take lips seriously. Blistex is here to help you do just that.
  //         </p>
  //       </>
  //     ),
  //   },
  sixthComponent: {
    image: paginationBanner,
    articleLink: "/sun-protection",
    text: (
      <>
        <h2 className="small">sun protection</h2>
        <h2 className="large">up next.</h2>
      </>
    ),
    align: "center",
  },
}

const Article = () => {
  return (
    <>
      <SEO title="Windburn" />
      <Layout noHeaderInLayout={true} makeFooterSticky={false} whiteMenu={true}>
        <div className="header-hero-container header-hero-container--banner">
          <Header />
          <BannerImageWithText content={tempData.hero} />
        </div>

        <TextBlock content={tempData.firstComponent} />
        <BannerImageWithText content={tempData.secondComponent} />
        <TextBlock content={tempData.thirdComponent} />
        <Banner content={tempData.forthComponent} />
        {/* <TextBlock content={tempData.fifthComponent} /> */}
        <BannerImageWithText content={tempData.sixthComponent} />
      </Layout>
    </>
  )
}

export default Article
